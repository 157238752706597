.modal-container {
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(230,230,230,0.7);
  z-index: 100;

  display: flex;
  justify-content: center;
}
.modal {
  margin-top: 100px;
  max-width: 500px;
}
.modal.content {
  background: white;
  color: #333333;
  border-radius: 5px;
  border: 1px solid #dddddd;
  margin-bottom: auto;
  padding: 20px;
  padding-top:15px;
}

.modal.content .form.row  label {
  font-weight: normal;
}

.modal-buttons {
  height:50px;
}

.modal-buttons button {
  margin-left: 1rem;
}