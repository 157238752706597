*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, monospace;
}

#app {
  height: 100%;
}

.view-header {
  background-color: #d9d9d9;
  padding: .7rem;
}

.view-body {
  padding: .7rem;
  overflow-x: clip;
  overflow-y: scroll;
}

.row {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.subrow {
  align-items: center;
  display: flex;
}

.column {
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.row.form {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.wrap {
  flex-wrap: wrap;
}

.grow {
  flex-grow: 1;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between, .space-between {
  justify-content: space-between;
}

.justify-space-around, .space-around {
  justify-content: space-around;
}

.small-text {
  opacity: .7;
  font-size: .8em;
}

.large-text {
  opacity: .7;
  font-size: 2em;
}

.double-spaced {
  line-height: 2.6em;
}

.clickable {
  cursor: pointer;
}

.float-right {
  float: right;
}

.flash {
  color: #811461;
  text-align: center;
  border: 1px dashed #811461;
  border-radius: .7rem;
  margin: .7rem;
  padding: 1rem;
  font-family: source-code-pro, Menlo, Monaco, Consolas, monospace;
}

.loader-container {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(230, 230, 230, .7);
  position: fixed;
  top: 0;
  left: 0;
}

.loader, .loader:before, .loader:after {
  -webkit-user-select: none;
  user-select: none;
  width: 1em;
  height: 4em;
  background: rgba(0, 0, 0, .4);
  -webkit-animation: 1s ease-in-out infinite load1;
  animation: 1s ease-in-out infinite load1;
}

.loader:before, .loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s;
  left: -1.5em;
}

.loader {
  -webkit-user-select: none;
  user-select: none;
  color: rgba(0, 0, 0, .4);
  text-indent: -9999em;
  margin: 160px auto;
  font-size: 11px;
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%, 80%, 100% {
    height: 4em;
    box-shadow: 0 0;
  }

  40% {
    height: 5em;
    box-shadow: 0 -2em;
  }
}

@keyframes load1 {
  0%, 80%, 100% {
    height: 4em;
    box-shadow: 0 0;
  }

  40% {
    height: 5em;
    box-shadow: 0 -2em;
  }
}

.modal-container {
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(230, 230, 230, .7);
  justify-content: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal {
  max-width: 500px;
  margin-top: 100px;
}

.modal.content {
  color: #333;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: auto;
  padding: 15px 20px 20px;
}

.modal.content .form.row label {
  font-weight: normal;
}

.modal-buttons {
  height: 50px;
}

.modal-buttons button {
  margin-left: 1rem;
}

.avatar-container.right {
  margin-left: 1rem;
}

.avatar-container.left {
  margin-right: 1rem;
}

.avatar {
  height: 2.6em;
  width: 2.6em;
  color: #555;
  background-color: #ccc;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatar .name {
  font-size: 1.3em;
  font-weight: 300;
}

.avatar img {
  width: 1.5em;
  height: 1.5em;
}

.presence-container .avatar {
  height: 1.2em;
  width: 1.2em;
}

.presence-container .avatar .name {
  font-size: .8em;
  font-weight: 500;
}

.presence-container .avatar img {
  width: 1em;
  height: 1em;
}

.share-button {
  background-color: rgba(37, 117, 165, .71);
  border-radius: 100%;
  margin-left: 1rem;
  padding: .3rem .5rem .3rem .3rem;
}

.share-button img {
  width: 1.5rem;
}

.conversation {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.small-ago {
  width: 3em;
  justify-content: flex-end;
  display: flex;
}

.small-status {
  background-color: rgba(170, 102, 0, .267);
  border-radius: .5rem;
  padding: 0 .6rem;
}

.chat {
  width: 100%;
  flex-direction: column-reverse;
  display: flex;
  overflow-x: visible;
}

.chat-bubble {
  color: #fff;
  white-space: pre;
  background-color: #37f;
  border-radius: .7rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: .3rem .8rem;
}

.chat-bubble.incoming {
  color: #000;
  background-color: #ddd;
  border-radius: 1rem;
}

.status-update {
  color: #eddf95;
  text-shadow: 0 1px 3px #000;
  background-color: #552f26;
  border-top: 2px solid #4e4147;
  border-bottom: 2px solid #c9aeab;
  align-content: center;
  justify-content: center;
  margin: .5rem -.8rem;
  padding: 1rem;
  font-weight: bold;
  display: flex;
  box-shadow: inset 0 .5rem 1rem .1rem rgba(0, 0, 0, .48);
}

.reply-form {
  padding-bottom: 1rem;
  padding-left: .7rem;
  padding-right: .5rem;
}

.message-input {
  width: 100%;
  resize: none;
  background-color: #ddd;
  border: 0;
  border-radius: 1.2rem;
  padding: .5em .5em .5em 1rem;
}

.message-input:focus {
  outline: none;
}

.send-button {
  padding-left: .5rem;
}

.presence span {
  white-space: nowrap;
  font-size: .9em;
}

.presence-container {
  border: 1px solid #aaa;
  border-radius: 100px;
  flex-wrap: wrap;
  margin-top: 5px;
  padding: 0 3px;
}

.presence {
  background: #cacaca;
  border-radius: .8em;
  flex-wrap: nowrap;
  padding-left: 2px;
  padding-right: 2px;
}

.presence-mini {
  background: rgba(0, 0, 0, .4);
  border-radius: 100%;
  font-size: .9em;
  line-height: 1em;
  position: relative;
  top: .2rem;
  left: -.4rem;
}

.login-container {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: calc(20vh - 20rem);
  display: flex;
}

.login-form {
  max-width: 50rem;
  border: 1px solid #444;
  border-radius: 2rem;
  margin: .7rem;
  padding: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
}

.login-form p {
  white-space: pre-wrap;
}

.form.row label {
  padding-right: 1rem;
  font-weight: bold;
}

/*# sourceMappingURL=index.e1b9c17a.css.map */
