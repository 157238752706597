
/* https://www.joshwcomeau.com/css/custom-css-reset/ */
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}


/*     blah            */


body {
  margin: 0;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, monospace;
}

#app {
  height: 100%;
}

.view-header {
  background-color: #d9d9d9;

  padding: 0.7rem;
}
.view-body {
  overflow-y: scroll;
  overflow-x: clip;
  padding: 0.7rem;
}





/*
   forests shared classes 
*/


.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.subrow {
  display: flex;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
.width100 {
  width: 100%;
}
.height100 {
  height: 100%;
}
.row.form {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.wrap {
  flex-wrap: wrap;
}
.grow {
  flex-grow: 1;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-space-between, .space-between {
  justify-content: space-between;
}
.justify-space-around, .space-around {
  justify-content: space-around;
}
.small-text {
  font-size: 0.8em;
  opacity: 0.7;
}
.large-text {
  font-size: 2em;
  opacity: 0.7;
}
.double-spaced {
  line-height: 2.6em;
}
.clickable {
  cursor: pointer;
}
.float-right {
  float: right;
}



.flash {
  color: rgb(129, 20, 97);
  font-family: source-code-pro, Menlo, Monaco, Consolas, monospace;
  text-align: center;
  border: 1px dashed rgb(129, 20, 97);
  border-radius: 0.7rem;
  margin: 0.7rem;
  padding: 1rem;
}










/*
   Full screen "loading" animation
*/

.loader-container {
  user-select: none;
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(230,230,230,0.7);
  z-index: 100;
}

.loader,
.loader:before,
.loader:after {
  user-select: none;
  background: rgba(0,0,0,0.4);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader {
  user-select: none;
  color: rgba(0,0,0,0.4);
  text-indent: -9999em;
  margin: 160px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
