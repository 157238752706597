
.login-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: calc(20vh - 20rem);
}

.login-form {
  margin: 0.7rem;
  border-radius: 2rem;
  border: 1px solid #444;
  max-width: 50rem;
  padding: 2rem;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, .2);
}

.login-form p {
  white-space: pre-wrap;
}

.form.row label {
  padding-right: 1rem;
  font-weight: bold;
}