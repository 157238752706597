
.share-button {
  border-radius: 100%;
  background-color: #2575a5b5;
  padding: 0.3rem;
  padding-right: 0.5rem;
  margin-left: 1rem;
}
.share-button img {
  width: 1.5rem;
}


.conversation {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.small-ago {
  width: 3em;
  display: flex;
  justify-content: flex-end;
}
.small-status {
  padding: 0.0rem 0.6rem;
  border-radius: 0.5rem;
  background-color: #a604;
}

.chat {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  overflow-x: visible;
}

.chat-bubble {
  padding: 0.3rem 0.8rem;
  border-radius: 0.7rem;
  background-color: #37f;
  color: white;
  white-space: pre;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.chat-bubble.incoming {
  border-radius: 1rem;
  background-color: #ddd;
  color: black;
}


.status-update {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  background-color: #552f26;
  box-shadow: inset 0 0.5rem 1rem 0.1rem #0000007a;
  color: #eddf95;
  text-shadow: #000 0px 1px 3px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1rem;
  border-top: 2px solid #4e4147;
  border-bottom: 2px solid #c9aeab;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.reply-form {
  padding-bottom: 1rem;
  padding-right: 0.5rem;
  padding-left: 0.7rem;
}

.message-input {
  width: 100%;
  border: 0;
  border-radius: 1.2rem;
  background-color: #ddd;
  padding: 0.5em;
  padding-left: 1rem;
  resize: none;
}

.message-input:focus{
  outline: none;
}

.send-button {
  padding-left: 0.5rem;
}

.presence span {
  font-size: 0.9em;
  white-space: nowrap;
} 

.presence-container {
  flex-wrap: wrap;
  padding: 0 3px;
  border: 1px solid #aaa;
  border-radius: 100px;
  margin-top: 5px;
}

.presence {
  background: #cacaca;
  border-radius: 0.8em;
  padding-left: 2px;
  padding-right: 2px;
  flex-wrap: nowrap;
}

.presence-mini {
  font-size: .9em;
  position: relative;
  left: -0.4rem;
  top: 0.2rem;
  line-height: 1em;
  background: #0006;
  border-radius: 100%;
}